import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import RewardItemListLoader from 'components/Loader/RewardItemListLoader';
import Item from './components/Item';
import Filter from './components/Filter';
// import {Card} from 'components/Card';
import IntlMessages from 'util/IntlMessages';

import { getLanguage } from 'reducers/SettingReducer';
import { getUser } from 'reducers/AuthReducer';

import { getRecordList, getLoading, getCount, getInputParams, getFilter } from 'reducers/RewardReducer';
import { getRecordList as getFavoriteRewardList } from 'reducers/FavoriteRewardReducer';
import { getReward, setRewardInputParams, getFavoriteReward, setRewardFilter } from 'actions/LoyaltyActions';

const RedemptionAward = ({ match }) => {
    const dispatch = useDispatch();

    const { awards, loading, count, inputParams, filter, favoriteReward, language, user } = useSelector(state => ({
        awards: getRecordList(state.reward),
        loading: getLoading(state.reward),
        count: Number(getCount(state.reward)),
        inputParams: getInputParams(state.reward),
        filter: getFilter(state.reward),
        favoriteReward: getFavoriteRewardList(state.favoriteReward),
        language: getLanguage(state.setting),
        user: getUser(state.auth),
    }));

    const [totalPageNo, setTotalPageNo] = useState(0);

    useEffect(() => {
        let entityList = _.get(user, 'member.entityList');
        if (!!entityList && entityList.length > 0) {
            let param = { ...filter, tagList: null };
            delete param.tagList;
            dispatch(setRewardFilter({ ...param, language: language.languageId }));
            dispatch(getReward({ queryParam: { ...param, language: language.languageId,  entityList: entityList }, ...inputParams }));

            dispatch(getFavoriteReward({ queryParam: {}, orderBy: 'sfid', isAsc: true }));
        }
    }, [language]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let tempPageNo = count / 9;
        // tempPageNo += 1;
        setTotalPageNo(Math.ceil(tempPageNo));
    }, [count, totalPageNo]);

    useEffect(() => {
        if (!awards) {
            dispatch(getReward({ queryParam: filter, ...inputParams, pageNo: 0 }));
        }
    }, [awards]);

    const handleChange = (e, value) => {
        if (filter.loyaltyPointStart === '' || filter.loyaltyPointEnd === '') return;
        dispatch(setRewardInputParams({ ...inputParams, pageNo: value - 1 }));
        dispatch(getReward({ queryParam: filter, ...inputParams, pageNo: value - 1 }));
    };

    const getfavoriteId = sfid => {
        for (let fr of favoriteReward) {
            let index = Object.values(fr.reward__c).indexOf(sfid);
            if (index > -1) {
                return _.get(fr, 'reward__c.sfid');
            }
        }
        return null;
    };

    return (
        <div className="reward-wrapper">
            <div className="row p-3">
                <div className="col-lg-3 col-md-4">
                    <Filter />
                </div>
                <div className="col-lg-9 col-md-8 col-sm-12 mb-4">
                    <div className="award-content">
                        <div className="p-0 row align-items-center">
                            {!loading ? (
                                !!awards && awards.length > 0 ? (
                                    awards.map((item, i) => {
                                        return (
                                            <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                                                <Item data={item} favoriteId={getfavoriteId(item.sfid)} />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="col text-center">
                                        <IntlMessages id="redemption.noRewardFound" />
                                    </div>
                                )
                            ) : (
                                <RewardItemListLoader />
                            )}
                        </div>

                        {count ? (
                            <div className="reward-pagination row justify-content-center">
                                <div className="pagination">
                                    <Pagination
                                        count={Number(totalPageNo)}
                                        onChange={(e, value) => handleChange(e, value)}
                                    ></Pagination>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};;

export default RedemptionAward;
