import AppConfig from 'constants/AppConfig';
const enMessages = {
    'checkout.shippingForm':'Shipping Form',
    'checkout.rewardRedeem': 'Reward Redemption',
    'checkout.itemname': 'Name',
    'checkout.quantity': 'Quantity',
    'checkout.username': 'User Name',
    'checkout.phonenumber': 'Phone Number',
    'checkout.addressLine1': 'Address Line 1',
    'checkout.addressLine2': 'Address Line 2',
    'checkout.city': 'City',
    'checkout.stateRegion': 'State / Region',
    'checkout.redemption': 'Redeem',
    'checkout.country': 'Country',
    'checkout.orderNo':'Order No',
    'checkout.ok': 'Ok',

    'checkout.placeholder.username':'Enter your name',
    'checkout.placeholder.phoneNumber':'Enter your phone number',
    'checkout.placeholder.addressLine1':'Enter your address line 1',
    'checkout.placeholder.addressLine2':'Enter your address line 2',
    'checkout.placeholder.city':'Enter your city',
    'checkout.placeholder.stateRegion':'Enter your state/region',

    'memberpoint.point': 'Point',
    'memberpoint.pointTable': 'Member Points',
    'memberpoint.pointHistory': 'Points History',
    'memberpoint.pointTransactionHistory': 'Point Transaction History',
    'memberpoint.source': 'Source',
    'memberpoint.historyId': 'Id',
    'memberpoint.pointTransaction': 'Point transaction#',
    'memberpoint.title': 'Type',
    'memberpoint.dateTime': 'Date & Time',
    'memberpoint.entity': 'Entity',
    'memberpoint.increment': 'Increment',
    'memberpoint.redemption': 'Redemption',
    'memberpoint.systemAdjustment': 'System Adjustment',
    'memberpoint.deposit': 'Deposit',
    'memberpoint.transaction': 'Transaction',
    'memberpoint.referral': 'Referral',
    'memberpoint.noHistoryFound':'No point record',

    'redemption.history': 'Redemption History',
    'redemption.historyTable': 'Redemption History',
    'redemption.redemptionId': 'Id',
    'redemption.redemptionOrders': 'Orders',
    'redemption.redemptionDate': 'Redemption Date',
    'redemption.rewardName': 'Redeemed Item',
    'redemption.quantity': 'Quantity',
    'redemption.redemptionEntity': 'Entity',
    'redemption.redemptionPoint': 'Point',
    'redemption.totalMemberPoint': 'Points',
    'redemption.status': 'Status',
    'redemption.status.Pending': 'Pending',
    'redemption.status.Cancelled': 'Cancelled',
    'redemption.status.Confirmed': 'Confirmed',
    'redemption.status.Processing': 'Processing',
    'redemption.status.Completed': 'Completed',
    'redemption.noRewardFound': 'Sorry, no matching rewards found',
    'redemption.noHistoryFound':'No redemption record',
    'redemption.processing':'Processing',

    'profile.title': 'User Profile',
    'profile.childAccount': 'User Portal',
    'profile.referralHistory': 'Referral History',
    'profile.tierLevel': 'Tier Level',
    'profile.loyaltyPoint': 'Total Member Point',
    'profile.loyaltyPointExpiryDate': 'Point Expiry Date(Y-M-D)',
    'profile.referralChat': 'Total Referral',
    'profile.pointChart': 'Point Activity',
    'profile.pointAccountChart': 'Referral Distribution',
    'profile.accountName': 'User name',
    'profile.referralNumber': 'Referral Number',
    'profile.entity': 'Entity',
    'profile.email': 'Email',
    'profile.memberCode': 'Member Code',
    'profile.referredMember': 'Referred Member',
    'profile.referredDate': 'Referred Date',
    'profile.verifiedDate': 'Verified Date',
    'profile.changePassword': 'Change Password',
    'profile.getMore': 'Get More',
    'profile.qrcodeTitle': 'Referral QR Code:',
    'profile.copyLink': 'Copy Link',

    'reward.filter': 'Filter',
    'reward.searchPH': 'What are you look for?',
    'reward.itemName': 'Item Name',
    'reward.search': 'Search...',
    'reward.pointRange': 'Point Range',
    'reward.tagging': 'Tagging',
    'reward.confirm': 'Confirm',
    'reward.selectedTagging': 'Selected Tagging',
    'reward.availableTagging': 'Available Tagging',
    'reward.noTagging': 'No Tagging Selected',
    'reward.viewDetail': 'View Detail',
    'reward.points': 'Points',
    'reward.result':'result',
    'reward.showMore':'Show More',
    'reward.showLess':'Show Less',
    'reward.details':'Details',

    'reward.rewardDetail': 'Reward Detail',
    'reward.back': 'Back',
    'reward.redeem': 'Redeem',
    'reward.redeemSuccessTitle':'Redemption request sent successfully',
    'reward.redeemSuccessContent':'Thank you for your redemption. You can check the redemption status from the redemption history page.',

    'reward.itemCode': 'Item Code',
    'reward.itemDescription': 'Description',
    'reward.itemRemark': 'Remark',
    'reward.itemTC': 'Terms & Conditions',
    'reward.shippingInfo': 'Shipping Information',
    'reward.noInformation': 'No Information Available',

    'dashboard.dashboard': 'Dashboard',
    'dashboard.welcomeMsg': 'Welcome back ',
    'dashboard.tier': 'Tier',
    'dashboard.referralHistory': 'Total Referral',
    'dashboard.loyaltyPointHistory': 'Member Point',
    'dashboard.pointStatus': 'Monthly Status',
    'dashboard.loyaltyPointExpiryDate': 'Expiry Date',
    'dashboard.referred': 'You have referred',
    'dashboard.referredPeople': 'times',
    'dashboard.referredMore1': 'Referral',
    'dashboard.referredMore2': 'more to get',
    'dashboard.point': 'Points',
    'dashboard.tierRequirement': 'Upgrade Requirement',
    'dashboard.depositRequirement': 'Deposit',
    'dashboard.transactionRequirement': 'Transaction',
    'dashboard.pointRequirement': 'Point',
    'dashboard.requirementLine1': 'Current (USD)',
    'dashboard.requirementLine2': 'To Next Tier (USD)',
    'dashboard.requirementLine3': 'more to upgrade next tier!',
    'dashboard.requirementLine4': 'more to maintain current tier!',
    'dashboard.requirementLine5': 'Current Point',
    'dashboard.requirementLine6': 'To Next Tier (Point)',
    'dashboard.topFavoriteReward': 'Top Favorite Reward',

    'login.memberLogin': 'Member Login',
    'login.signIn': 'Sign in',
    'login.forgotPassword': 'Forgot Password',
    'login.enterEmail': 'Email Address',
    'login.enterPassword': 'Password',
    'login.termOfService': 'Term of Service',
    'login.right': `© ${AppConfig.year} ${AppConfig.brandName} All Rights Reserved`,

    'changepw.changePassword': 'Change Password',
    'changepw.email': 'Email Address',
    'changepw.oldPassword': 'Old Password',
    'changepw.newPassword': 'New Password',
    'changepw.confirmPassword': 'Confirm Password',
    'changepw.confirm': 'Confirm',

    'forgot.enterEmail': 'Email Address',
    'forgot.enterConfirmationCode': 'Confirmation Code',
    'forgot.enterNewPassword': 'New Password',
    'forgot.enterConfirmationPassword': 'Confirm Password',
    'forgot.resetPassword': 'Reset Password',
    'forgot.getCode': 'Get Code',
    'forgot.toLogin': 'Already a member?',

    'sidebar.profile': 'Profile / Quick Access',
    'sidebar.redemption': 'Redemption',
    'sidebar.redemptionNew': 'Redemption Mobile',
    'sidebar.redemptionItem': 'Reward',
    'sidebar.redemptionHistory': 'Redemption History',
    'sidebar.redemptionHistoryNew': 'Redemption History Mobile',
    'sidebar.point': 'Member Point',
    'sidebar.pointHistory': 'Points History',
    'sidebar.pointHistoryNew': 'Points History Mobile',
    'sidebar.promotion': 'Promotion',
    'sidebar.tier': 'Member Tier',
    'sidebar.tierHistory': 'Tier',
    'sidebar.ecrmLogin': 'eCRM Login',
    'sidebar.loginPost': 'Login',
    'sidebar.loginPre': 'Quick',

    'sidebar.dashboard': 'Dashboard',
    'sidebar.memberreg': 'Manage Accounts',
    'sidebar.modules': 'Modules',
    'widgets.logOut': 'LOGOUT',
    'widgets.ecrmLogin': 'eCRM Login',
    'widgets.eblLogin': 'App Download',
    'widgets.eieLogin': 'HCRM Login',
    'widgets.popupAlertTips':
        "For better Safari browsing experience of Quick Access, you are suggested to complete below settings before using our 'Quick Access': \n 1. Launch 'Settings'\n 2. Choose 'Safari'\n 3.Toggle off 'Block Pop-ups'",
    'widgets.action': 'Quick Login',
    'widgets.passwordRequiremnt': 'Password Requirement:',

    'error.default': 'Unexpected Error. Please try again later',
    'error.10001': 'Username and password does not match the policy',
    'error.10002': 'Invalid username and password',
    'error.10003': 'User is not confirmed',
    'error.10004': 'Username already exist',
    'error.10005': 'Confirmation code is expired',
    'error.10006': 'Confirmation code is not matched',
    'error.10007': 'Member is not activated',
    'error.10008': 'User does not exist',
    'error.10009': 'New Password Required',
    'error.10010': 'Session not found',
    'error.10011': 'User is disabled. Please contact admin',
    'error.10012': 'Password attempts exceeded. Please try again later',
    'error.10013': 'User Expired. Please change password',
    'error.10017': 'Password Expired. Please change password',

    'input.emailRequired': 'Email is required',
    'input.emailRestricted': 'Must be a email',
    'input.passwordRequired': 'Password is required',
    'input.passwordMatched': 'Passwords must match',
    'input.confirmationCodeRequired': 'Confirmation Code is required',
    'input.specialCharRequired': 'Special character required',
    'input.numberRequired': 'Number required',
    'input.uppercaseRequired': 'Uppercase letter required',
    'input.lowercaseRequired': 'Lowercase letter required',
    'input.minLengthRequired': 'Must be at least 8 characters',
    'input.digitOnly': 'Should have digits only',

    'table.body.noMatch': 'Sorry, no matching records found',
    'table.body.sort': 'Sort',
    'table.body.sorFor': 'Sort for',
    'table.pagination.next': 'Next Page',
    'table.pagination.previous': 'Previous Page',
    'table.pagination.rowsPerPage': 'Rows per page:',
    'table.pagination.displayRows': 'of',
    'table.toolbar.search': 'Search',
    'table.toolbar.downloadCsv': 'Download CSV',
    'table.toolbar.print': 'Print',
    'table.toolbar.viewColumns': 'View Columns',
    'table.toolbar.filterTable': 'Filter Table',
    'table.filter.all': 'All',
    'table.filter.title': 'FILTERS',
    'table.filter.reset': 'RESET',
    'table.viewColumns.title': 'Show Columns',
    'table.viewColumns.titleAria': 'Show/Hide Table Columns',
    'table.selectedRows.text': 'row(s) selected',
    'table.selectedRows.delete': 'Delete',
    'table.selectedRows.deleteAria': 'Delete Selected Rows',

    'tier.platinum': 'Platinum',
    'tier.diamond': 'Diamond',
    'tier.gold': 'Gold',
    'tier.silver': 'Silver',
    'tier.': '',

    'memberreg.form.not_member': 'Not a Member yet?',
    'memberreg.form.title': 'Add an Existing Account',
    'memberreg.form.title_sign_up': 'Existing Client Registration',
    'memberreg.form.title_new_customer': 'New Client Registration',
    'memberreg.form.title_new_customer_url': 'https://www.empfs.com/en-US/form/bullion-personal',
    'memberreg.form.membershipCode': 'Membership Code',
    'memberreg.form.invalid_email': 'Invalid Email',
    'memberreg.form.entity.entity_EBL': 'EBL',
    'memberreg.form.entity.entity_EIE': 'EIE',
    'memberreg.form.entity.entity_UK': 'UK',
    'memberreg.form.entity.entity_EBL.email': 'EBL Registered Email Address',
    'memberreg.form.entity.entity_EIE.email': 'EIE Registered Email Address',
    'memberreg.form.mobile': 'Mobile',
    'memberreg.form.mobile_area': 'Area Code',
    'memberreg.form.mobile_otp': 'OTP Code',
    'memberreg.form.mobile_otp_verify': 'Verify',
    'memberreg.form.mobile_otp_verified': 'Verified',
    'memberreg.form.mobile_otp_invalid': 'Invalid OTP',
    'memberreg.form.mobile_otp_cancel': 'Cancel',
    'memberreg.form.mobile_otp_confirm': 'Confirm',

    'memberreg.form.preferred_email': 'Membership Email',
    'memberreg.form.application_processing':
        'Your application is being processed. If you need further support, please contact our customer service team.',
    'memberreg.form.info.accounts':
        'We will bind your multiple trading accounts with this email to our loyalty program.',
    'memberreg.form.info.communications':
        'We will send messages and marketing communications to the above email address. Please ensure the email address is accessible and up-to-date.',
    'memberreg.form.terms_conditions': 'By signing up or in, you agree to our {terms} and {privacy}',
    'memberreg.form.terms_conditions_url': 'Terms & Conditions',
    'memberreg.form.privacy_url': 'Privacy Policy',
    'memberreg.form.thanks.message': 'Thank you for joining us! Our team will get in touch with you soon!',
    'memberreg.form.thanks.promotion': 'Do you know our latest promotions? Find out now!',
    'memberreg.form.submit_button': 'Submit',

    'promotion.luckydraw.event': 'Lucky Draw',
};
export default enMessages;
