import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { MdContentCopy } from 'react-icons/md';
import { AppBar, Typography, IconButton, Button, Tab, Tabs, Tooltip, CircularProgress, Grid } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import moment from 'moment';
import { Bar, Doughnut } from 'react-chartjs-2';
import { injectIntl, intlShape } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import QRCode from 'qrcode.react';

import IntlMessages from 'util/IntlMessages';
import { Card } from 'components/Card';
import TabPanel from 'components/Widgets/TabPanel';
import * as _ from 'lodash';
import CRMLoginButton from 'components/Header/CRMLoginButton';

import { getChildAccount, getReferralHistory, getYearlyResult, validateUserSession, crmLogin } from 'actions';
import { CONSTANT } from 'constants/constant';
import { getLanguage } from 'reducers/SettingReducer';

import { getUser } from 'reducers/AuthReducer';

import {
    getChildAccount as getChildAccountList,
    getReferralHistory as getReferralHistoryList,
    getYearlyResult as getYearlyResultMap,
    getLoading,
} from 'reducers/MemberReducer';

const UserProfile = ({ match, intl }) => {
    const accountNameC = intl.formatMessage({ id: 'profile.accountName' });
    const entityC = intl.formatMessage({ id: 'profile.entity' });
    const emailC = intl.formatMessage({ id: 'profile.email' });
    const ecrmLoginC = intl.formatMessage({ id: 'widgets.action' });

    const referredNumberC = intl.formatMessage({ id: 'profile.referralNumber' });
    const referredMemberC = intl.formatMessage({ id: 'profile.referredMember' });
    const referredDateC = intl.formatMessage({ id: 'profile.referredDate' });
    const verifiedDateC = intl.formatMessage({ id: 'profile.verifiedDate' });

    const ECRM_LOGIN = 'ECRM_LOGIN';

    const childAccountColumnMap = {
        [accountNameC]: 'name',
        [entityC]: 'entity__c',
        [emailC]: 'ecrm_user_name__c',
        // [ecrmLoginC]: ECRM_LOGIN,
    };

    const childAccountColumns = Object.keys(childAccountColumnMap);
    const childAccountColumnAPIs = Object.values(childAccountColumnMap);

    const referralHistoryColumnMap = {
        [referredNumberC]: 'name',
        [referredMemberC]: 'referred_member__c.name',
        [referredDateC]: 'referral_date__c',
        [verifiedDateC]: 'verified_date__c',
    };

    const referralHistoryColumns = Object.keys(referralHistoryColumnMap);
    const referralHistoryColumnAPIs = Object.values(referralHistoryColumnMap);

    const [childAccountShowList, setChildAccountShowList] = useState([]);
    const [referralHistoryShowList, setReferralHistoryShowList] = useState([]);
    const [QRCodeShowList, setQRCodeShowList] = useState([]);

    // const [accountUrl, setAccountUrl] = useState('');

    const [childAccountOpts] = useState({
        orderBy: 'name',
        isAsc: true,
    });

    const [referralHistoryOpts] = useState({
        orderBy: 'name',
        isAsc: true,
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const { user, childAccountList, referralHistoryList, yearlyResult, loading, language } = useSelector(state => ({
        user: getUser(state.auth),
        childAccountList: getChildAccountList(state.member),
        referralHistoryList: getReferralHistoryList(state.member),
        yearlyResult: getYearlyResultMap(state.member),
        loading: getLoading(state.member),
        language: getLanguage(state.setting),
    }));

    const referralBarOptions = {
        scales: {
            xAxes: [
                {
                    barThickness: 30,
                    ticks: {
                        autoSkip: false,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        stepSize: 2,
                        beginAtZero: true,
                        userCallback: function (label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                                return label;
                            }
                        },
                    },
                },
            ],
        },
    };

    const pointBarOptions = {
        scales: {
            xAxes: [
                {
                    barThickness: 30,
                    ticks: {
                        autoSkip: false,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        userCallback: function (label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                                return label;
                            }
                        },
                    },
                },
            ],
        },
    };

    const tradingAccountTableOptions = {
        print: false,
        customToolbarSelect: () => {},
        selectableRows: 'none',
        filter: false,
        pagination: false,
        search: false,
        elevation: 0,
        downloadOptions: {
            filename: 'Trading_Account_Profile_' + language.short,
        },
        download: false,
        textLabels: {
            body: {
                noMatch: intl.formatMessage({ id: 'table.body.noMatch' }),
                toolTip: intl.formatMessage({ id: 'table.body.sort' }),
                columnHeaderTooltip: column => intl.formatMessage({ id: 'table.body.sorFor' }) + ` ${column.label}`,
            },
            pagination: {
                next: intl.formatMessage({ id: 'table.pagination.next' }),
                previous: intl.formatMessage({ id: 'table.pagination.previous' }),
                rowsPerPage: intl.formatMessage({ id: 'table.pagination.rowsPerPage' }),
                displayRows: intl.formatMessage({ id: 'table.pagination.displayRows' }),
            },
            toolbar: {
                search: intl.formatMessage({ id: 'table.toolbar.search' }),
                downloadCsv: intl.formatMessage({ id: 'table.toolbar.downloadCsv' }),
                print: intl.formatMessage({ id: 'table.toolbar.print' }),
                viewColumns: intl.formatMessage({ id: 'table.toolbar.viewColumns' }),
                filterTable: intl.formatMessage({ id: 'table.toolbar.filterTable' }),
            },
            filter: {
                all: intl.formatMessage({ id: 'table.filter.all' }),
                title: intl.formatMessage({ id: 'table.filter.title' }),
                reset: intl.formatMessage({ id: 'table.filter.reset' }),
            },
            viewColumns: {
                title: intl.formatMessage({ id: 'table.viewColumns.title' }),
                titleAria: intl.formatMessage({ id: 'table.viewColumns.titleAria' }),
            },
            selectedRows: {
                text: intl.formatMessage({ id: 'table.selectedRows.text' }),
                delete: intl.formatMessage({ id: 'table.selectedRows.delete' }),
                deleteAria: intl.formatMessage({ id: 'table.selectedRows.deleteAria' }),
            },
        },
    };

    const referralHistoryTableOptions = {
        print: false,
        customToolbarSelect: () => {},
        selectableRows: 'none',
        filter: false,
        pagination: false,
        search: false,
        elevation: 0,
        downloadOptions: {
            filename: 'Referral_History_Profile_' + language.short,
        },
        download: false,
        textLabels: {
            body: {
                noMatch: intl.formatMessage({ id: 'table.body.noMatch' }),
                toolTip: intl.formatMessage({ id: 'table.body.sort' }),
                columnHeaderTooltip: column => intl.formatMessage({ id: 'table.body.sorFor' }) + ` ${column.label}`,
            },
            pagination: {
                next: intl.formatMessage({ id: 'table.pagination.next' }),
                previous: intl.formatMessage({ id: 'table.pagination.previous' }),
                rowsPerPage: intl.formatMessage({ id: 'table.pagination.rowsPerPage' }),
                displayRows: intl.formatMessage({ id: 'table.pagination.displayRows' }),
            },
            toolbar: {
                search: intl.formatMessage({ id: 'table.toolbar.search' }),
                downloadCsv: intl.formatMessage({ id: 'table.toolbar.downloadCsv' }),
                print: intl.formatMessage({ id: 'table.toolbar.print' }),
                viewColumns: intl.formatMessage({ id: 'table.toolbar.viewColumns' }),
                filterTable: intl.formatMessage({ id: 'table.toolbar.filterTable' }),
            },
            filter: {
                all: intl.formatMessage({ id: 'table.filter.all' }),
                title: intl.formatMessage({ id: 'table.filter.title' }),
                reset: intl.formatMessage({ id: 'table.filter.reset' }),
            },
            viewColumns: {
                title: intl.formatMessage({ id: 'table.viewColumns.title' }),
                titleAria: intl.formatMessage({ id: 'table.viewColumns.titleAria' }),
            },
            selectedRows: {
                text: intl.formatMessage({ id: 'table.selectedRows.text' }),
                delete: intl.formatMessage({ id: 'table.selectedRows.delete' }),
                deleteAria: intl.formatMessage({ id: 'table.selectedRows.deleteAria' }),
            },
        },
    };

    // useEffect(() => {
    //     setAccountUrl(_.get(user, 'member.memberCodeUrl') + _.get(user, 'member.member_code__c'));
    // }, [user, setAccountUrl]);

    useEffect(() => {
        dispatch(validateUserSession(history));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('Run B');
        dispatch(getChildAccount({ queryParam: {}, ...childAccountOpts }));
        dispatch(getReferralHistory({ queryParam: {}, ...referralHistoryOpts }));
        dispatch(getYearlyResult());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setChildAccountShowList(list => []);
        if (childAccountList && childAccountList.length > 0)
            childAccountList.forEach((data, index) => {
                let listToPush = [];
                for (let APIName of childAccountColumnAPIs) {
                    if (APIName === ECRM_LOGIN) {
                        if (data.entity__c === CONSTANT.ENTITY_SVG) {
                            listToPush.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs="8">
                                        <CRMLoginButton
                                            entity={CONSTANT.ENTITY_SVG}
                                            accountEmail={_.get(data, childAccountColumnMap[emailC])}
                                            action={crmLogin}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip
                                            enterTouchDelay={50}
                                            leaveTouchDelay={5000}
                                            interactive
                                            title={
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {intl.formatMessage({
                                                        id: 'widgets.popupAlertTips',
                                                    })}
                                                </div>
                                            }
                                        >
                                            <IconButton>
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            );
                            continue;
                        }
                        if (data.entity__c === CONSTANT.ENTITY_EIE) {
                            listToPush.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs="8">
                                        <CRMLoginButton
                                            entity={CONSTANT.ENTITY_EIE}
                                            accountEmail={_.get(data, childAccountColumnMap[emailC])}
                                            id="widgets.eieLogin"
                                            action={crmLogin}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip
                                            enterTouchDelay={50}
                                            leaveTouchDelay={5000}
                                            interactive
                                            title={
                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                    {intl.formatMessage({
                                                        id: 'widgets.popupAlertTips',
                                                    })}
                                                </div>
                                            }
                                        >
                                            <IconButton>
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            );
                            continue;
                        }
                        if (data.entity__c === CONSTANT.ENTITY_EBL) {
                            listToPush.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs="8">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={() => window.open(CONSTANT.ENTITY_EBL_LOGIN, '_blank')}
                                        >
                                            <IntlMessages id="widgets.eblLogin" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            );
                            continue;
                        }
                    }
                    listToPush.push(_.get(data, APIName));
                }
                setChildAccountShowList(list => [...list, listToPush]);
            });

        //shwo the qr code according to the account list
        setQRCodeShowList(list => []);
        if (childAccountList && childAccountList.length > 0) {
            const uniqeChildAccountList=_.uniqBy(childAccountList, 'entity__c');
            uniqeChildAccountList.map(data => {
                let listToPush = [];
                let url = user.member.memberCodeUrlList[data.entity__c] + _.get(user, 'member.member_code__c');
                if (!!user.member.memberCodeUrlList[data.entity__c]) {
                    listToPush.push(
                        <div className="col-md-4 col-sm-6 col-lg-4">
                            <div className="col-12 text-center">{data.entity__c}</div>
                            <div className="col-12 text-center">
                                <QRCode value={url}></QRCode>
                            </div>
                            <Link
                                to="#"
                                className="d-block col-12 text-center"
                                href="javascript:void(0);"
                                onClick={() => navigator.clipboard.writeText(url)}
                            >
                                <IntlMessages id="profile.copyLink" />
                            </Link>
                        </div>
                    );
                    setQRCodeShowList(list => [...list, listToPush]);
                }
            });
        }
    }, [childAccountList, language]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setReferralHistoryShowList(list => []);
        if (referralHistoryList && referralHistoryList.length > 0)
            referralHistoryList.forEach((data, index) => {
                let listToPush = [];
                for (let APIName of referralHistoryColumnAPIs) {
                    if (APIName === 'referral_date__c' && _.get(data, APIName)) {
                        listToPush.push(moment(_.get(data, APIName)).utc().local().format('YYYY-MM-DD HH:mm:ss'));
                        continue;
                    }
                    if (APIName === 'verified_date__c' && _.get(data, APIName)) {
                        listToPush.push(moment(_.get(data, APIName)).utc().local().format('YYYY-MM-DD HH:mm:ss'));
                        continue;
                    }
                    listToPush.push(_.get(data, APIName));
                }
                setReferralHistoryShowList(list => [...list, listToPush]);
            });
    }, [referralHistoryList]); // eslint-disable-line react-hooks/exhaustive-deps

    const [loyaltyPointChartData, setLoyaltyPointChartData] = useState({});
    const [referralHistoryChartData, setReferralHistoryChartData] = useState({});
    const [accountPointChartData, setAccountPointChartData] = useState({});

    const assignColor = number => {
        if (number > 0) return 'rgba(255, 99, 132, 0.2)';
        else return 'rgba(54, 162, 235, 0.2)';
    };

    useEffect(() => {
        console.log(loyaltyPointChartData);
    }, [loyaltyPointChartData]);

    useEffect(() => {
        setLoyaltyPointChartData({});
        setReferralHistoryChartData({});
        setAccountPointChartData({});

        if (yearlyResult) {
            if (
                yearlyResult.loyaltyPoint &&
                Object.keys(yearlyResult.loyaltyPoint).length > 0 &&
                yearlyResult.loyaltyPoint.overall &&
                Object.keys(yearlyResult.loyaltyPoint.overall).length > 0
            ) {
                let labels = Object.keys(yearlyResult.loyaltyPoint.overall);
                let typeMap = {};
                let colorMap = {};
                Object.keys(yearlyResult.loyaltyPoint.overall).forEach(year => {
                    let point = yearlyResult.loyaltyPoint.overall[year];
                    Object.keys(point).forEach(type => {
                        let colorTempList = colorMap[type] ? colorMap[type] : [];
                        colorTempList.push(assignColor(point[type]));
                        colorMap[type] = colorTempList;

                        let tmplist = typeMap[type] ? typeMap[type] : [];
                        tmplist.push(Math.abs(point[type]));
                        typeMap[type] = tmplist;
                    });
                });

                let datasets = [];
                Object.keys(typeMap).forEach(type => {
                    datasets.push({
                        label: type,
                        data: typeMap[type],
                        backgroundColor: colorMap[type],
                    });
                });
                let data = {
                    labels: labels,
                    datasets: datasets,
                };

                setLoyaltyPointChartData(data);
            }

            if (
                yearlyResult.loyaltyPoint &&
                Object.keys(yearlyResult.loyaltyPoint).length > 0 &&
                yearlyResult.loyaltyPoint.resultList &&
                Object.keys(yearlyResult.loyaltyPoint.resultList).length > 0
            ) {
                let labels = [];
                let dataset = [];

                yearlyResult.loyaltyPoint.resultList.forEach(result => {
                    labels.push(result.account.name);
                    dataset.push(result.total);
                });

                let data = {
                    labels: labels,
                    datasets: [
                        {
                            data: dataset,
                        },
                    ],
                };

                setAccountPointChartData(data);
            }

            if (yearlyResult.referral && Object.keys(yearlyResult.referral).length > 0) {
                let labels = Object.keys(yearlyResult.referral);
                let data = [];

                Object.keys(yearlyResult.referral).forEach(year => {
                    data.push(yearlyResult.referral[year]);
                });

                let datasets = [
                    {
                        label: 'Number of referral',
                        data: data,
                    },
                ];

                setReferralHistoryChartData({
                    labels: labels,
                    datasets: datasets,
                });
            }
        }
    }, [yearlyResult]);

    const [tanvalue, setTabValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangePassword = event => {
        history.push('/app/users/change-password');
    };

    return (
        <div className="profile-wrapper">
            <div>
                <Card customClass="h-100 shadow profile-header-card">
                    <div className="bg-color-2">
                        <div className="px-4 pt-5 pb-2 row justify-content-between">
                            <div className="px-4 profile-card-title">{_.get(user, 'member.name')}</div>
                            <div className="px-4">
                                <Button variant="contained" color="default" onClick={e => handleChangePassword()}>
                                    <span>
                                        <IntlMessages id="profile.changePassword" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <hr className="my-1 mx-4" />
                        <div className="pl-4 pb-2">{_.get(user, 'member.email__c')}</div>
                    </div>

                    <div className="row row-eq-height justify-content-start mt-4 pb-4 px-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="mb-3">
                                {<IntlMessages id="profile.memberCode" />} :{' '}
                                <span className="profile-card-subtitle mb-2">
                                    {_.get(user, 'member.member_code__c', '')}
                                    <IconButton
                                        color="primary"
                                        aria-label="Copy"
                                        onClick={() => {
                                            navigator.clipboard.writeText(_.get(user, 'member.member_code__c', ''));
                                        }}
                                        className="p-0 pl-3 pr-3 rounded"
                                    >
                                        <MdContentCopy />
                                    </IconButton>
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="mb-3">
                                {<IntlMessages id="profile.tierLevel" />} : {' '}
                                <span className="profile-card-subtitle mb-2">
                                    {_.get(user, 'member.tier__c.name', 'No Tier')}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="mb-3">
                                {<IntlMessages id="profile.loyaltyPoint" />} : {' '}
                                <span className="profile-card-subtitle mb-2">
                                    <i className="zmdi zmdi-toll"></i>
                                    &nbsp;
                                    {_.get(user, 'member.total_loyalty_point__c', 0) -
                                        _.get(user, 'member.redeemed_loyalty_point__c', 0)}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="mb-3">
                                {<IntlMessages id="profile.loyaltyPointExpiryDate" />} :{' '}
                                <span className="profile-card-subtitle mb-2">
                                    {_.get(user, 'member.loyalty_point_expired_date__c')
                                        ? moment(_.get(user, 'member.loyalty_point_expired_date__c')).format(
                                              'YYYY-MM-DD'
                                          )
                                        : ''}
                                </span>
                            </div>
                        </div>

                        {/* <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    <IntlMessages id="profile.qrcodeTitle" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="row col-12">{QRCodeShowList}</div>
                            </div>
                        </div> */}
                    </div>
                </Card>
            </div>

            <div className="row row-eq-height justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <Card customClass="h-100 shadow">
                        <MUIDataTable
                            title={
                                <Typography component="span">
                                    <IntlMessages id="profile.childAccount" />
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            style={{ marginLeft: 15, position: 'relative', top: 4 }}
                                        />
                                    )}
                                </Typography>
                            }
                            data={childAccountShowList}
                            columns={childAccountColumns}
                            options={tradingAccountTableOptions}
                        />
                    </Card>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                    <Card customClass="h-100 shadow">
                        <MUIDataTable
                            title={
                                <Typography component="span">
                                    <IntlMessages id="profile.referralHistory" />
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            style={{ marginLeft: 15, position: 'relative', top: 4 }}
                                        />
                                    )}
                                </Typography>
                            }
                            data={referralHistoryShowList}
                            columns={referralHistoryColumns}
                            options={referralHistoryTableOptions}
                        />
                    </Card>
                </div>
            </div>
            {/*
            <div className="mt-2">
                <Card customClass="h-100 shadow">
                    <div>
                        <AppBar position="static" elevation={0}>
                            <Tabs variant="scrollable" value={tanvalue} onChange={handleTabChange}>
                                <Tab label={<IntlMessages id="profile.referralChat" />} id="referralChart" />
                                <Tab label={<IntlMessages id="profile.pointChart" />} id="memberPointChart" />
                                <Tab label={<IntlMessages id="profile.pointAccountChart" />} id="pointAccountChart" />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tanvalue} index={0}>
                            <Bar
                                data={referralHistoryChartData}
                                options={referralBarOptions}
                                height={100}
                                width={200}
                            />
                        </TabPanel>
                        <TabPanel value={tanvalue} index={1}>
                            <Bar data={loyaltyPointChartData} options={pointBarOptions} height={100} width={200} />
                        </TabPanel>
                        <TabPanel value={tanvalue} index={2}>
                            <Doughnut data={accountPointChartData} height={100} width={200} />
                        </TabPanel>
                    </div>
                </Card>
            </div> */}
        </div>
    );
};
UserProfile.prototype = {
    intl: intlShape.isRequired,
};

export default injectIntl(UserProfile);
