import AppConfig from 'constants/AppConfig';
const zhMessages = {
    'checkout.shippingForm':'運輸表格',
    'checkout.rewardRedeem': '獎勵換領',
    'checkout.itemname': '名稱',
    'checkout.quantity': '數量',
    'checkout.username': '用戶名稱',
    'checkout.phonenumber': '電話號碼',
    'checkout.addressLine1': '地址 1',
    'checkout.addressLine2': '地址 2',
    'checkout.city': '城市',
    'checkout.stateRegion': '州/地區',
    'checkout.redemption': '換取',
    'checkout.country': '國家',
    'checkout.orderNo':'編號',
    'checkout.ok': '確定',

    'checkout.placeholder.username':'輸入您的姓名',
    'checkout.placeholder.phoneNumber':'輸入您的電話號碼',
    'checkout.placeholder.addressLine1':'輸入您的地址第1行',
    'checkout.placeholder.addressLine2':'輸入您的地址第2行',
    'checkout.placeholder.city':'輸入您的城市',
    'checkout.placeholder.stateRegion':'輸入您所在的州/地區',

    'memberpoint.point': '積分',
    'memberpoint.pointTable': '會員積分',
    'memberpoint.pointHistory': '積分紀錄',
    'memberpoint.pointTransactionHistory': '積分交易記錄',
    'memberpoint.source': '來源',
    'memberpoint.historyId': '編號',
    'memberpoint.pointTransaction': '積分交易#',
    'memberpoint.title': '類型',
    'memberpoint.dateTime': '日期和時間',
    'memberpoint.entity': '業務單位',

    'memberpoint.increment': '增加',
    'memberpoint.redemption': '兌換',
    'memberpoint.systemAdjustment': '系統調整',
    'memberpoint.deposit': '入金',
    'memberpoint.transaction': '交易',
    'memberpoint.referral': '推薦',
    'memberpoint.noHistoryFound':'您尚未有積分紀錄',

    'redemption.history': '兌換紀錄',
    'redemption.historyTable': '兌換紀录',
    'redemption.redemptionId': '編號',
    'redemption.redemptionOrders':'編號',
    'redemption.redemptionDate': '兌換日期',
    'redemption.rewardName': '兌換產品',
    'redemption.quantity': '數量',
    'redemption.redemptionEntity': '業務單位',
    'redemption.redemptionPoint': '積分',
    'redemption.totalMemberPoint': '積分',
    'redemption.status': '狀態',
    'redemption.status.Pending': '審核中',
    'redemption.status.Cancelled': '取消',
    'redemption.status.Confirmed': '確認',
    'redemption.status.Processing': '處理中',
    'redemption.status.Completed': '完成',
    'redemption.noRewardFound': '對不起，沒有找到匹配的獎勵',
    'redemption.noHistoryFound':'您尚未有兌換記錄',
    'redemption.processing':'處理中',

    'profile.title': '用戶資料',
    'profile.childAccount': '用戶系統',
    'profile.referralHistory': '推薦記錄',
    'profile.tierLevel': '層級',
    'profile.loyaltyPoint': '積分',
    'profile.loyaltyPointExpiryDate': '積分有效日期(年-月-日)',
    'profile.referralChat': '累積推薦人數',
    'profile.pointChart': '累積積分',
    'profile.pointAccountChart': '累積積分分佈',
    'profile.accountName': '用戶名稱',
    'profile.referralNumber': '推薦號碼',
    'profile.entity': '所屬賬號',
    'profile.email': '登記電郵',
    'profile.memberCode': '會員編號',
    'profile.referredMember': '推薦會員',
    'profile.referredDate': '推薦日期',
    'profile.verifiedDate': '驗證日期',
    'profile.changePassword': '更改密碼',
    'profile.getMore': '獲取更多',
    'profile.qrcodeTitle': '推薦二維碼:',
    'profile.copyLink': '複製二維碼鏈接',

    'reward.filter': '篩選條件',
    'reward.searchPH': '您在尋找...',
    'reward.itemName': '獎勵名稱',
    'reward.search': '搜索...',
    'reward.pointRange': '積分範圍',
    'reward.tagging': 'Tagging',
    'reward.confirm': '確認',
    'reward.selectedTagging': '選定的標籤',
    'reward.availableTagging': '可用的標籤',
    'reward.noTagging': '未選擇標籤',
    'reward.viewDetail': '查看詳情',
    'reward.points': '積分',
    'reward.result':'结果',
    'reward.showMore':'展示更多',
    'reward.showLess':'展示較少',
    'reward.details':'詳情',

    'reward.rewardDetail': '獎勵詳細',
    'reward.back': '上一頁',
    'reward.redeem': '換取獎勵',
    'reward.redeemSuccessTitle':'已提交兌換申請',
    'reward.redeemSuccessContent':'驚喜禮遇盡在EFSG Rewards！ 您可於兌換記錄查詢兌換狀態。',

    'reward.itemCode': '獎勵編號',
    'reward.itemDescription': '獎勵簡介',
    'reward.itemRemark': '備註',
    'reward.shippingInfo': '運輸信息',
    'reward.itemTC': '條款及細則',
    'reward.noInformation': '無可用信息',

    'dashboard.dashboard': '會員概覽',
    'dashboard.welcomeMsg': '歡迎回來!',
    'dashboard.tier': '層級',
    'dashboard.referralHistory': '推薦記錄',
    'dashboard.loyaltyPointHistory': '積分紀錄',
    'dashboard.pointStatus': '每月表現',
    'dashboard.loyaltyPointExpiryDate': '有效日期',
    'dashboard.referred': '您已推薦',
    'dashboard.referredPeople': '次數',
    'dashboard.referredMore1': '推薦額外',
    'dashboard.referredMore2': '人領取',
    'dashboard.point': '積分',
    'dashboard.tierRequirement': '升級要求',
    'dashboard.depositRequirement': '存款要求',
    'dashboard.transactionRequirement': '交易要求',
    'dashboard.pointRequirement': '積分要求',
    'dashboard.requirementLine1': '現有(美金)',
    'dashboard.requirementLine2': '升級需要(美金)',
    'dashboard.requirementLine3': '升級下一層!',
    'dashboard.requirementLine4': '保持當前等級!',
    'dashboard.requirementLine5': '現有積分',
    'dashboard.requirementLine6': '升級需要(積分)',
    'dashboard.topFavoriteReward': '最受歡迎的獎勵',

    'login.memberLogin': '會員登入',
    'login.signIn': '登入',
    'login.forgotPassword': '忘記密碼',
    'login.enterEmail': '輸入電郵地址',
    'login.enterPassword': '輸入密碼',
    'login.termOfService': '服務條款',
    'login.right': `© ${AppConfig.year} ${AppConfig.brandName} 版權所有`,

    'changepw.changePassword': '更改密碼',
    'changepw.email': '電郵地址',
    'changepw.oldPassword': '舊密碼',
    'changepw.newPassword': '新密碼',
    'changepw.confirmPassword': '重複輸入密碼',
    'changepw.confirm': '確認',

    'forgot.enterEmail': '輸入電郵地址',
    'forgot.enterConfirmationCode': '驗證碼',
    'forgot.enterNewPassword': '新密碼',
    'forgot.enterConfirmationPassword': '確認密碼',
    'forgot.resetPassword': '重設密碼',
    'forgot.getCode': '取得驗證碼',
    'forgot.toLogin': '已經有賬號了？',

    'sidebar.profile': '個人資料 / 快速登入',
    'sidebar.redemption': '兌換獎勵',
    'sidebar.redemptionNew': 'Redemption Mobile',
    'sidebar.redemptionItem': '獎勵',
    'sidebar.redemptionHistory': '兌換紀录',
    'sidebar.redemptionHistoryNew': 'Redemption History Mobile',
    'sidebar.pointNew': 'Member Point Mobile',
    'sidebar.point': '積分',
    'sidebar.pointHistory': '積分紀錄',
    'sidebar.pointHistoryNew': 'Points History Mobile',
    'sidebar.promotion': '夏日狂歡大抽獎',
    'sidebar.tier': '會員分類',
    'sidebar.tierHistory': '分類記錄',
    'sidebar.ecrmLogin': 'eCRM 登入',
    'sidebar.loginPost': '登入',
    'sidebar.loginPre': '後台快速',

    'sidebar.dashboard': '會員概覽',
    'sidebar.memberreg': '管理賬戶',
    'sidebar.modules': '模塊',
    'widgets.logOut': '登出',
    'widgets.ecrmLogin': 'eCRM 登入',
    'widgets.eblLogin': '下載程式',
    'widgets.eieLogin': 'HCRM 登入',
    'widgets.popupAlertTips':
        '為了讓您有更好的Safari瀏覽體驗，我們建議您使用我們的「快捷登入鍵」前完成以下iPhone設定: \n 1. 開啟「設定」\n 2. 選擇「Safari」\n 3. 關閉 「阻擋彈出式視窗」',
    'widgets.action': '後台快速登入',
    'widgets.passwordRequiremnt': '密碼要求：',

    'error.default': '錯誤。 請稍後再試',
    'error.10001': '用戶名和密碼與策略不匹配',
    'error.10002': '無效的用戶名和密碼',
    'error.10003': '未確認用戶',
    'error.10004': '用戶名已存在',
    'error.10005': '確認代碼已過期',
    'error.10006': '確認碼不匹配',
    'error.10007': '成員未激活',
    'error.10008': '用戶不存在',
    'error.10009': '需要新密碼',
    'error.10010': '未找到會話',
    'error.10011': '用戶已禁用。請聯繫管理員',
    'error.10012': '超過密碼嘗試次數。 請稍後再試 ',
    'error.10013': '用戶已過期。請更改密碼',
    'error.10017': '密碼已過期。請更改密碼',

    'input.emailRequired': '需要電子郵件',
    'input.emailRestricted': '必須是一個電子郵件',
    'input.passwordRequired': '需要密碼',
    'input.passwordMatched': '密碼必須匹配',
    'input.confirmationCodeRequired': '需要確認碼',
    'input.specialCharRequired': '需要特殊字符',
    'input.numberRequired': '需要數字',
    'input.uppercaseRequired': '需要大寫字母',
    'input.lowercaseRequired': '需要小寫字母',
    'input.minLengthRequired': '必須至少8個字符',
    'input.digitOnly': '只能有數字',

    'table.body.noMatch': '暫沒有相關紀錄',
    'table.body.sort': '排序',
    'table.body.sorFor': '排序為',
    'table.pagination.next': '下一頁',
    'table.pagination.previous': '上一頁',
    'table.pagination.rowsPerPage': '每頁行數：',
    'table.pagination.displayRows': '分',
    'table.toolbar.search': '搜索',
    'table.toolbar.downloadCsv': '下載CSV',
    'table.toolbar.print': '打印',
    'table.toolbar.viewColumns': '查看列',
    'table.toolbar.filterTable': '篩選表',
    'table.filter.all': '全部”',
    'table.filter.title': '篩選',
    'table.filter.reset': '重置',
    'table.viewColumns.title': '顯示列',
    'table.viewColumns.titleAria': '顯示/隱藏表列',
    'table.selectedRows.text': '已選擇的行',
    'table.selectedRows.delete': '刪除',
    'table.selectedRows.deleteAria': '刪除選定的行',

    'tier.platinum': '白金',
    'tier.diamond': '鑽石',
    'tier.gold': '黃金',
    'tier.silver': '白銀',
    'tier.': '',

    'memberreg.form.not_member': '成為會員',
    'memberreg.form.title': '添加現有交易賬戶',
    'memberreg.form.title_sign_up': '現有客戶登記',
    'memberreg.form.title_new_customer': '全新客戶申請',
    'memberreg.form.title_new_customer_url': 'https://www.empfs.com/form/bullion-personal',
    'memberreg.form.membershipCode': '會員號碼',
    'memberreg.form.invalid_email': '電郵地址無效',
    'memberreg.form.entity.entity_EBL': 'EBL',
    'memberreg.form.entity.entity_EIE': 'EIE',
    'memberreg.form.entity.entity_UK': 'UK',
    'memberreg.form.entity.entity_EBL.email': 'EBL登記電郵地址',
    'memberreg.form.entity.entity_EIE.email': 'EIE登記電郵地址',
    'memberreg.form.mobile': '手提電話號碼',
    'memberreg.form.mobile_area': '電話區號',
    'memberreg.form.mobile_otp': '接收一次性密碼',
    'memberreg.form.mobile_otp_verify': '驗證',
    'memberreg.form.mobile_otp_verified': '已驗證',
    'memberreg.form.mobile_otp_invalid': '一次性密碼無效',
    'memberreg.form.mobile_otp_cancel': '取消',
    'memberreg.form.mobile_otp_confirm': '確認',

    'memberreg.form.preferred_email': '會員電郵',
    'memberreg.form.application_processing': '我們正在處理您的申請。如需協助，歡迎與我們聯絡。',
    'memberreg.form.info.accounts': '我們會以此電郵地址登記積分計劃，並用以統一您的交易賬戶之積分計算。',
    'memberreg.form.info.communications': '通過電子郵件接收我們的最新優惠及活動資訊。',
    'memberreg.form.terms_conditions': '登記會員後，即表示您同意會員計劃內的 {terms} 以及 {privacy}。',
    'memberreg.form.terms_conditions_url': '條款和條件',
    'memberreg.form.privacy_url': '隱私政策',
    'memberreg.form.thanks.message': '感謝您的登記，我們的團隊會儘快與您聯絡。',
    'memberreg.form.thanks.promotion': '最新推廣活動',
    'memberreg.form.submit_button': '提交',

    'promotion.luckydraw.event':'抽獎活動',
};

export default zhMessages;
