import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
    Typography,
    Container,
    Button,
    Grid,
    TextField,
    Card,
    CardHeader,
    CardContent,
    FormControlLabel,
    Checkbox,
    Tooltip,
    FormGroup,
    CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CheckCircle, RadioButtonUnchecked, Info } from '@material-ui/icons';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomMaterialInput from 'components/CustomMaterialInput';
import AlertBoxEmailOTP from 'components/Widgets/AlertBoxEmailOTP';
import { MobileAreaCodeList } from 'constants/MemberForm';
import { CONSTANT } from 'constants/constant';
import { httpPost } from 'lib/request';

import { injectIntl, intlShape } from 'react-intl';

import SessionLayout from 'container/SessionLayout';
import IntlMessages from 'util/IntlMessages';
import LanguageProvider from 'components/Header/LanguageProvider';

import { getLoading, getError } from 'reducers/AuthReducer';
import { getLanguage } from 'reducers/SettingReducer';

import { postMemberRegRecord } from 'actions';
// import { Card } from 'components/Card';
import * as _ from 'lodash';

const NewMemberForm = ({ history, intl }) => {
    const dispatch = useDispatch();

    const { loading, SMSlanguage } = useSelector(state => ({
        loading: getLoading(state.memberReg),
        SMSlanguage: getLanguage(state.setting).short,
        // error: getError(state.auth),
    }));

    const {
        control,
        handleSubmit,
        watch,
        errors,
        setValue,
        formState: { isSubmitSuccessful },
    } = useForm({
        criteriaMode: 'all',
        defaultValues: {
            entity_EBL: false,
            entity_EIE: false,
            EBL_email: '',
            EIE_email: '',
            area_code: '',
            mobile: '',
            preferred_email: '',
        },
        // resolver: yupResolver()
    });

    const [entity_EBL, setEntityEBL] = useState(false);
    const [entity_EIE, setEntityEIE] = useState(false);
    const [OTP_verified, setOTPVerified] = useState(false);
    const [OTPstatus, setOTPstatus] = useState(false);
    const [OTPCode, setOTPCode] = useState('');
    const [OTPCodeErrorMessage, setOTPCodeErrorMessage] = useState('');
    const fieldMobile = watch('mobile');
    const fieldAreaCode = watch('area_code');

    const OTPHandleClose = () => {
        setOTPstatus(false);
    };
    const getCodeButtonClick = async () => {
        if (fieldMobile) {
            try {
                setOTPstatus(true);
                const mobile = (fieldAreaCode + fieldMobile).toString();
                await httpPost('memberreg/mobileOTPRequest', {
                    language: SMSlanguage,
                    mobile,
                });
            } catch (error) {
                setOTPstatus(false);
            }
        }
    };

    const confirmOTPButtonClick = async otp => {
        if (otp) {
            try {
                const mobile = (fieldAreaCode + fieldMobile).toString();
                await httpPost('memberreg/mobileOTPVerify', {
                    otp,
                    mobile,
                });
                //     //valid OTP
                setOTPstatus(false);
                setOTPCodeErrorMessage('');
                setOTPVerified(true);
            } catch (error) {
                // invalid OTP
                setOTPCodeErrorMessage(
                    intl.formatMessage({
                        id: 'memberreg.form.mobile_otp_invalid',
                    })
                );
            }
        }
    };

    const entities = [
        {
            entity: CONSTANT.ENTITY_EBL,
            fieldName: 'entity_EBL',
            fieldValue: entity_EBL,
            email: 'EBL_email',
            setFieldName: setEntityEBL,
        },
        {
            entity: CONSTANT.ENTITY_EIE,
            fieldName: 'entity_EIE',
            fieldValue: entity_EIE,
            email: 'EIE_email',
            setFieldName: setEntityEIE,
        },
    ];

    const onSubmit = async formData => {
        let param = {
            mobileAreaCode: formData.area_code,
            mobile: formData.mobile,
            preferred_email: formData.preferred_email,
        };
        if (entity_EBL) {
            param.entity = CONSTANT.ENTITY_EBL;
            param.email = formData[_.find(entities, { entity: CONSTANT.ENTITY_EBL }).email];

            dispatch(postMemberRegRecord(param, history));
        }
        if (entity_EIE) {
            param.entity = CONSTANT.ENTITY_EIE;
            param.email = formData[_.find(entities, { entity: CONSTANT.ENTITY_EIE }).email];

            dispatch(postMemberRegRecord(param, history));
        }
    };

    return (
        <SessionLayout
            loading={loading}
            sessionHeaderRight={
                <div className="row justify-content-center align-self-center">
                    <LanguageProvider />
                </div>
            }
        >
            <div className="session-component-container w-100 shadow">
                <div className="session-body text-center h-100 px-5">
                    <div className="session-head my-4">
                        <h2 className="font-weight-bold">
                            <IntlMessages id="memberreg.form.title_sign_up" />
                        </h2>
                        <div className="my-3">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={intl.formatMessage({ id: 'memberreg.form.title_new_customer_url' })}
                            >
                                <IntlMessages id="memberreg.form.title_new_customer" />
                            </a>
                        </div>
                    </div>
                    {!isSubmitSuccessful ? (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="pb-3">
                                {entities.map(entity => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={entity.fieldValue}
                                                    onChange={e => {
                                                        entity.setFieldName(e.target.checked);
                                                    }}
                                                    icon={<RadioButtonUnchecked />}
                                                    checkedIcon={<CheckCircle style={{ color: '#00b2d3' }} />}
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: `memberreg.form.entity.${entity.fieldName}`,
                                            })}
                                        />
                                    );
                                })}
                            </div>
                            {entities.map(entity => {
                                if (entity.fieldValue)
                                    return (
                                        <div className="pb-3">
                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                <Grid item xs={10}>
                                                    <CustomMaterialInput
                                                        rules={{
                                                            required: true,
                                                            pattern: {
                                                                value: /^([a-zA-Z0-9]*[-_]?[a-zA-Z0-9\.]+)*@([a-zA-Z0-9]*[-_]?[a-zA-Z0-9]+)+[\.][A-Za-z]{2,3}([\.][A-Za-z]{2})?$/,
                                                            },
                                                        }}
                                                        control={control}
                                                        label={intl.formatMessage({
                                                            id: `memberreg.form.entity.${entity.fieldName}.email`,
                                                        })}
                                                        name={entity.email}
                                                        fullWidth
                                                        variant="outlined"
                                                        inputProps={{
                                                            maxLength: 100,
                                                        }}
                                                        error={!!errors[entity.email]}
                                                    />
                                                </Grid>
                                                <Grid container item xs={2} justifyContent="center">
                                                    <Tooltip
                                                        enterTouchDelay={50}
                                                        interactive
                                                        title={intl.formatMessage({
                                                            id: 'memberreg.form.info.accounts',
                                                        })}
                                                    >
                                                        <Info />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    );
                            })}

                            <div className="pb-3">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item xs={4} md={3}>
                                        <Controller
                                            rules={{ required: true }}
                                            render={props => (
                                                <Autocomplete
                                                    {...props}
                                                    freeSolo
                                                    options={MobileAreaCodeList.map(option => option.value)}
                                                    disabled={OTP_verified}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label={intl.formatMessage({
                                                                id: 'memberreg.form.mobile_area',
                                                            })}
                                                            variant="outlined"
                                                            error={!!errors.area_code}
                                                        />
                                                    )}
                                                    onInputChange={(event, data) => {
                                                        props.onChange(data);
                                                    }}
                                                    onChange={(event, data) => {
                                                        props.onChange(data);
                                                    }}
                                                />
                                            )}
                                            name="area_code"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={8} md={7}>
                                        <CustomMaterialInput
                                            rules={{ required: true }}
                                            control={control}
                                            label={intl.formatMessage({ id: 'memberreg.form.mobile' })}
                                            name="mobile"
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                            disabled={OTP_verified}
                                            error={!!errors.mobile}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} md={2} justifyContent="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={getCodeButtonClick}
                                            disabled={!fieldMobile || !fieldAreaCode || OTP_verified}
                                        >
                                            {OTP_verified
                                                ? intl.formatMessage({ id: 'memberreg.form.mobile_otp_verified' })
                                                : intl.formatMessage({ id: 'memberreg.form.mobile_otp_verify' })}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="pb-3">
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item xs={10}>
                                        <CustomMaterialInput
                                            rules={{
                                                required: true,
                                                pattern: {
                                                    value: /^([a-zA-Z0-9]*[-_]?[a-zA-Z0-9\.]+)*@([a-zA-Z0-9]*[-_]?[a-zA-Z0-9]+)+[\.][A-Za-z]{2,3}([\.][A-Za-z]{2})?$/,
                                                },
                                            }}
                                            control={control}
                                            label={intl.formatMessage({ id: 'memberreg.form.preferred_email' })}
                                            name="preferred_email"
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                            error={!!errors.preferred_email}
                                        />
                                    </Grid>
                                    <Grid container item xs={2} justifyContent="center">
                                        <Tooltip
                                            enterTouchDelay={50}
                                            interactive
                                            title={intl.formatMessage({
                                                id: 'memberreg.form.info.communications',
                                            })}
                                        >
                                            <Info />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </div>

                            <Button
                                disabled={loading || (!entity_EBL && !entity_EIE) || !OTP_verified}
                                color="primary"
                                className="btn-block text-white w-100"
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                <IntlMessages id="memberreg.form.submit_button" />
                            </Button>
                            <Typography color="textSecondary">
                                <IntlMessages
                                    id="memberreg.form.terms_conditions"
                                    values={{
                                        terms: (
                                            <a
                                                href={CONSTANT.MEMBERREG_TERM_AND_CONDITION}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <IntlMessages id="memberreg.form.terms_conditions_url" />
                                            </a>
                                        ),
                                        privacy: (
                                            <a href={CONSTANT.MEMBERREG_PRIVACY} rel="noreferrer" target="_blank">
                                                <IntlMessages id="memberreg.form.privacy_url" />
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                        </form>
                    ) : (
                        <Card>
                            <CardContent>
                                <Typography>
                                    {intl.formatMessage({
                                        id: 'memberreg.form.thanks.message',
                                    })}
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                    {loading ? (
                        <span>
                            <IntlMessages id="forgot.toLogin" />
                        </span>
                    ) : (
                        <div className="my-3">
                            <Link to="/signin">
                                <IntlMessages id="forgot.toLogin" />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <AlertBoxEmailOTP
                open={OTPstatus}
                handleClose={OTPHandleClose}
                confirmButtonClick={confirmOTPButtonClick}
                OTPCode={OTPCode}
                setOTPCode={setOTPCode}
                OTPCodeErrorMessage={OTPCodeErrorMessage}
            />
        </SessionLayout>
    );
};

NewMemberForm.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(NewMemberForm);
